import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { ReactSVG } from "react-svg";

import ServiceTile from "@components/molecules/ServiceTile";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";
import LazyHydrate from "@components/utils/LazyHydrate";

import { ServiceTileStoryblokProps } from "./types";

const ServiceTileStoryblok: React.FC<ServiceTileStoryblokProps> = ({
  blok
}) => {
  const {
    icon,
    title,
    link: {
      url,
      cached_url: cachedUrl,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    }
  } = blok;

  const newestUrl = fullSlug || cachedUrl;

  const link = {
    href: url || newestUrl,
    rel,
    target
  };

  return (
    <LazyHydrate whenVisible>
      <ServiceTile
        icon={<ReactSVG src={tryToReplaceDomain(icon.filename)} />}
        {...{ title, link, ...storyblokEditable(blok) }}
      />
    </LazyHydrate>
  );
};

export default ServiceTileStoryblok;
